<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Crear portada</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" label-width="100px" :model="form">
              <el-form-item label="Tipo">
                <ValidationProvider name="tipo" rules="required" v-slot="{ errors }">
                  <el-select
                  v-on:change="handleSearch()"
                  clearable
                  class="width-100"
                  size="mini"
                  v-model="form.type"
                  filterable
                  placeholder="Tipo">
                    <el-option
                      v-for="itemType in optionType"
                      :key="itemType.value"
                      :label="itemType.label"
                      :value="itemType.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="sección">
                <ValidationProvider name="sección" rules="required" v-slot="{ errors }">
                  <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.id_reference"
                  filterable
                  placeholder="Tipo">
                    <el-option
                      v-for="itemTitle in listItemsTitle"
                      :key="itemTitle._id"
                      :label="itemTitle.title"
                      :value="itemTitle._id">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="titulo" rules="required|min:1|max:100" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen">
                <div v-if="form.image">
                  <el-image :src=" $urlGlobalApi +form.image">
                    <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  drag
                  action=""
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb (1065 x 755)</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Publicar">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onUpdate"
                type="primary"
                :disabled="invalid"
                icon="el-icon-upload2">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { getCoverPage, updateCoverPage, filter } from '@/api/coverpage'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        title: '',
        image: '',
        id_reference: '',
        status: true,
        type: ''
      },
      optionType: [{
        value: 'release',
        label: 'Comunicado'
      }, {
        value: 'new',
        label: 'Noticia'
      }, {
        value: 'course',
        label: 'Curso'
      }, {
        value: 'video',
        label: 'Video'
      }, {
        value: 'survey',
        label: 'Cuestionario'
      }],
      listItemsTitle: {},
      loading: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async handleSearch () {
      this.loading = true
      await filter(this.form)
        .then(response => {
          console.log(response)
          this.listItemsTitle = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async fetchData () {
      this.loading = true
      await getCoverPage(this.$route.params._id)
        .then((response) => {
          this.form = response.data
          console.log(response.data)
          this.handleSearch()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      await updateCoverPage(this.$route.params._id, this.form)
        .then((response) => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Actualizado con exito!',
            type: 'success'
          })
          this.$router.push({ name: 'coverpage' })
        })
        .catch(this.responseCatch)
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'image')
      formData.append('width', '755')
      formData.append('height', '1065')
      formData.append('title', 'imagen-cover')
      formData.append('alt', 'imagen-cover')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res
      console.log(this.form.image)
    }
  }
}
</script>
